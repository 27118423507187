import React, { useState, useEffect } from 'react'
import Navbar from './components/navbar/Navbar';
import PrimerTorneo from "./pages/PrimerTorneo";
import SegundoTorneo from "./pages/SegundoTorneo";
import CargaExcel from "./pages/CargaExcel";
import Inicio from "./pages/Inicio";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

function App() {

  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  })

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    })
    window.sessionStorage.setItem("width", window.innerWidth);
    window.sessionStorage.setItem("height", window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', detectSize)

    return () => {
      window.removeEventListener('resize', detectSize)
    }
  }, [windowDimenion])

  return (
    <>
      <Router>
        
        <Routes>
          <Route path='/' element={<Inicio/>} />
          <Route path='/primer-torneo' element={<PrimerTorneo/>} />
          <Route path='/segundo-torneo' element={<SegundoTorneo/>} />
          <Route path='/carga-excel' element={<CargaExcel/>} />
        </Routes>

      </Router>
    </>
  );
}

export default App;
