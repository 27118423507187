import React from 'react';
import Excel from '../components/excel/Excel';
import Navbar from '../components/navbar/Navbar';

function CargaExcel() {
  return (
    <>
      <aside style={{ marginTop: '3%', marginLeft: '5%' }}>
        <Navbar id="navbarsito" />
      </aside>

      <div className='primer-torneo'>
        <div style={{ marginLeft:'4%', marginBottom:'3%' }}>
          <a href="/" >
            {window.innerWidth > 992
              ?
              <>
                <img
                  src={require("../images/new/asdbetprpro2.png")}
                  width="80%"
                  className="d-inline-block align-top"
                  alt="Logo de eliminatorias StarPlay"
                />
              </>
              :
              <div style={{ textAlign: 'center'}}>
                <img
                  src={require("../images/new/asdbetprpro3.png")}
                  width="70%"
                  className="d-inline-block align-top"
                  alt="Logo de eliminatorias StarPlay"
                />
              </div>
            }
          </a>
        </div>
      </div>
      <div className='tabla-excel'>
        <Excel />
      </div>
    </>
  );
}

export default CargaExcel;